





































import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixin';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { namespace } from 'vuex-class';
import { authStoreActions } from '@/store/auth.store';
import User from '@/models/User';
import { ROUTE_COMPANY, ROUTE_DASHBOARD } from '@/router/routes';
import Company from '@/models/Company';
import { applicationStoreMutations } from '@/store/application.store';
import { UserRoles } from '@/enum/UserRoles';
import Manager from '@/models/Manager';

const AuthStore = namespace('auth');
const ApplicationStore = namespace('application');

@Component({
    mixins: [validationMixin],
    validations: {
        login: { required },
        password: { required }
    }
})
export default class LoginView extends mixins(ErrorMessageHandlerMixin) {

    @AuthStore.Action(authStoreActions.LOGIN)
    private loginAction!: (payload: { login: string, password: string }) => Promise<User>;

    @ApplicationStore.Mutation(applicationStoreMutations.SET_COMPANY)
    public saveCompany!: (company: Company | null) => void;

    public title: string = process.env.VUE_APP_TITLE!;

    public login: string | null = null;
    public password: string | null = null;

    public isFormSubmitted: boolean = false;
    public isLoading: boolean = false;

    /**
     * Tries to login
     */
    public async onLoginFormSubmit() {
        this.isFormSubmitted = true;
        // Trigger validation
        this.$v.$touch();

        if (!this.$v.$invalid && !this.isLoading) {
            try {
                this.isLoading = true;
                const user = await this.loginAction({
                    login: this.login!,
                    password: this.password!
                });

                if (user.role === UserRoles.ADMIN || user.role === UserRoles.MANAGER) {
                    await this.$router.push({ name: ROUTE_COMPANY });
                } else {
                    this.saveCompany(user.company as Company);
                    await this.$router.push({ name: ROUTE_DASHBOARD });
                }
            } catch (e) {
                switch (e.status) {
                    case 422:
                        this.$notifyErrorSimplified('LOGIN.INACTIVE');
                        break;
                    case 401:
                        this.$notifyErrorSimplified('LOGIN.UNAUTHORIZED');
                        break;
                    case 403:
                        this.$notifyErrorSimplified('LOGIN.FORBIDDEN');
                        break;
                    default:
                        this.$notifyErrorSimplified('LOGIN.DEFAULT');
                        break;
                }
            } finally {
                this.isLoading = false;
            }
        }
    }

}
